import React, { useMemo, useState } from 'react';

import DeckPositionWithPreferences from 'client/app/apps/workflow-builder/panels/workflow-settings/deck-options/DeckPositionWithPreferences';
import { ScreenRegistry } from 'client/app/registry';
import {
  getLabwareTypesByPosition,
  LabwareType,
} from 'client/app/state/LabwarePreference';
import { LayoutPreferences } from 'common/types/bundle';
import { ParseDeviceConfigResponse } from 'common/types/device';
import Carrier, { isCarrier } from 'common/ui/components/simulation-details/mix/Carrier';
import DeckLayout from 'common/ui/components/simulation-details/mix/DeckLayout';
import MixGrid from 'common/ui/components/simulation-details/mix/MixGrid';
import Workspace from 'common/ui/components/Workspace/Workspace';

type Props = {
  activeLabwareType?: LabwareType;
  deckConfig: ParseDeviceConfigResponse;
  currentPreferences?: LayoutPreferences;
  onAddPosition: (newPosition: string) => void;
  onRemovePosition: (oldPosition: string) => void;
  isDisabled: boolean;
};

export default React.memo(function DeckLayoutWithPreferences(props: Props) {
  const {
    activeLabwareType,
    deckConfig,
    currentPreferences,
    onAddPosition,
    onRemovePosition,
    isDisabled,
  } = props;

  const [gridVisible, setGridVisible] = useState(true);

  const validLabwareTypesByPositions = useMemo(
    () => getLabwareTypesByPosition(deckConfig.validAddresses),
    [deckConfig],
  );

  const selectedLabwareTypesByPositions = useMemo(
    () => getLabwareTypesByPosition(currentPreferences),
    [currentPreferences],
  );

  const deckLayout = new DeckLayout({
    before: deckConfig.layout,
    after: deckConfig.layout,
    version: '',
  });

  return (
    <Workspace
      isGridSwitchVisible
      gridVisible={gridVisible}
      setGridVisible={setGridVisible}
      isShowAllButtonVisible
      isShowHelpButtonVisible
      canvasControlVariant="light_float"
      initialShowAll
      logCategory={ScreenRegistry.WORKFLOW}
    >
      <div style={deckLayout.deckBounds}>
        <MixGrid deckLayout={deckLayout} gridVisible={gridVisible} />
        {deckLayout.getAllDeckPositions().map(deckPosition => {
          const positionName = deckPosition.deckPositionName;
          if (isCarrier(deckPosition)) {
            return <Carrier key={positionName} deckPosition={deckPosition} />;
          }
          return (
            <DeckPositionWithPreferences
              key={positionName}
              deckPosition={deckPosition}
              activeLabwareType={activeLabwareType}
              selectedLabwareTypes={selectedLabwareTypesByPositions[positionName]}
              validLabwareTypes={validLabwareTypesByPositions[positionName]}
              onAddPosition={onAddPosition}
              onRemovePosition={onRemovePosition}
              isDisabled={isDisabled}
            />
          );
        })}
      </div>
    </Workspace>
  );
});
