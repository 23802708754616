import React, { useCallback, useMemo } from 'react';

import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { ProtocolElementParameter } from 'client/app/apps/protocols/ProtocolElementParameter';
import doNothing from 'common/lib/doNothing';
import { alphanumericCompare } from 'common/lib/strings';
import { ParameterEditorConfigurationSpec } from 'common/types/commonConfiguration';
import Colors from 'common/ui/Colors';

type Props = {
  elementInstanceId: string;
  entries: { [key: string]: any };
  enabledKeys?: string[];
  valueEditor: ParameterEditorConfigurationSpec;
  onToggle?: (
    checked: boolean,
    editor: ParameterEditorConfigurationSpec,
    value: any,
    key: string,
  ) => void;
  showKeys: boolean;
  isDisabled: boolean;
};

export function EntrySelector({
  elementInstanceId,
  entries,
  enabledKeys = [],
  valueEditor,
  onToggle,
  showKeys,
  isDisabled,
}: Props) {
  const sortedEntries = useMemo(
    () => Object.entries(entries).sort(([a], [b]) => alphanumericCompare(a, b)),
    [entries],
  );

  const checkedEntries = useMemo(
    () => sortedEntries.map(([key]) => enabledKeys.includes(key)),
    [enabledKeys, sortedEntries],
  );

  const handleCheckEntry = useCallback(
    (checked: boolean, key: string, value: any) => {
      onToggle?.(checked, valueEditor, value, key);
    },
    [onToggle, valueEditor],
  );

  return (
    <Wrapper spacing={2}>
      {sortedEntries.map(([key, value], index) => {
        return (
          <EntryItem key={key}>
            <EntryValue>
              {showKeys ? <Typography color={Colors.GREY_50}>{key}</Typography> : null}
              <ProtocolElementParameter
                elementInstanceId={elementInstanceId}
                value={value}
                editor={valueEditor}
                onChange={doNothing}
                isDisabled
              />
            </EntryValue>
            {onToggle && (
              <Tooltip
                disableInteractive
                title="Check the box to expose just this value as a parameter."
                arrow
              >
                <Checkbox
                  size="small"
                  checked={checkedEntries[index]}
                  disabled={isDisabled}
                  onChange={(_, checked) => handleCheckEntry(checked, key, value)}
                />
              </Tooltip>
            )}
          </EntryItem>
        );
      })}
    </Wrapper>
  );
}

const Wrapper = styled(Stack)(({ theme }) => ({
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(4),
  marginLeft: theme.spacing(1),
}));

const EntryItem = styled('div')({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '5fr 1fr',
});

const EntryValue = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(-3),
  transform: 'scale(0.9)',
}));
