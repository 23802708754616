import React, { useCallback, useEffect, useMemo, useState } from 'react';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import DOMPurify from 'dompurify';
import produce from 'immer';

import DeviceSettingOption from 'client/app/apps/workflow-builder/panels/DeviceSettingOption';
import Panel from 'client/app/apps/workflow-builder/panels/Panel';
import {
  getSelectedMainDevice,
  useGetDeviceCommonForWorkflow,
} from 'client/app/apps/workflow-builder/panels/workflow-settings/deck-options/deckOptionsPanelUtils';
import DeckOptionsParameter from 'client/app/apps/workflow-builder/panels/workflow-settings/deck-options/DeckOptionsParameter';
import DeviceAdvancedOptionsList from 'client/app/apps/workflow-builder/panels/workflow-settings/DeviceAdvancedOptions';
import DeviceSelectorCard from 'client/app/apps/workflow-builder/panels/workflow-settings/devices/DeviceSelectorCard';
import LHPolicyUploadEditor from 'client/app/apps/workflow-builder/panels/workflow-settings/LHPolicyUploadEditor';
import SettingsPanelContainer from 'client/app/apps/workflow-builder/panels/workflow-settings/SettingsPanelContainer';
import { TipTypeSettingOption } from 'client/app/apps/workflow-builder/panels/workflow-settings/TipTypeSettingOption';
import { useWorkflowSettingsState } from 'client/app/apps/workflow-builder/panels/workflow-settings/workflowSettingsState';
import { INPUTPLATES, TIPTYPES } from 'client/app/lib/workflow/workflowConfigProperties';
import { ScreenRegistry } from 'client/app/registry';
import {
  useWorkflowBuilderDispatch,
  useWorkflowBuilderSelector,
} from 'client/app/state/WorkflowBuilderStateContext';
import doNothing from 'common/lib/doNothing';
import {
  ConfiguredDevice,
  ParameterValue,
  ParameterValueDict,
} from 'common/types/bundle';
import { updateConfigAfterSet } from 'common/types/bundleConfigUtils';
import { DirectUploadSingleValueLegacy } from 'common/types/fileParameter';
import IconButtonWithPopper from 'common/ui/components/IconButtonWithPopper';
import TextField from 'common/ui/filaments/TextField';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useTextFieldChange from 'common/ui/hooks/useTextFieldChange';

type Props = {
  className: string;
  onClose: () => void;
};

const SettingsPanel = React.memo(function SettingsPanel({ className, onClose }: Props) {
  const classes = useStyles();
  const dispatch = useWorkflowBuilderDispatch();

  const {
    isReadonly,
    requiresDevice,
    showDeckOptions,
    showCustomLHPolicies,
    workflowConfig,
    configuredDevicesForSelectedStage,
    allConfiguredDevices,
  } = useWorkflowSettingsState();

  const { selectedStageId, stages } = useWorkflowBuilderSelector(state => state);
  const selectedStage = stages.find(stage => stage.id === selectedStageId);

  const devices = selectedStage
    ? configuredDevicesForSelectedStage
    : allConfiguredDevices;

  const commonDevices = useGetDeviceCommonForWorkflow(devices);

  const { selectedDevice: mainDevice } = getSelectedMainDevice(devices, commonDevices);

  const paramValueDict = useMemo<ParameterValueDict>(() => {
    if (!selectedStage) {
      return workflowConfig.global as ParameterValueDict;
    }
    return (mainDevice ?? {}) as ParameterValueDict;
  }, [mainDevice, selectedStage, workflowConfig.global]);

  const [stageNameValidationState, setStageNameValidationState] = useState(
    selectedStage?.name ?? '',
  );
  useEffect(() => {
    setStageNameValidationState(selectedStage?.name ?? '');
  }, [selectedStage?.name]);

  const isStageNameValid = (name: string) => {
    // Disallows entering empty strings
    return name.trim() !== '';
  };

  const onStageNameChange = useTextFieldChange((newName: string) => {
    const sanitized = DOMPurify.sanitize(newName.trimStart());
    setStageNameValidationState(sanitized);
    if (selectedStageId && isStageNameValid(sanitized)) {
      dispatch({
        type: 'updateStageName',
        payload: { stageId: selectedStageId, newName: sanitized },
      });
    }
  });

  const handleSelectedDevicesChange = useCallback(
    (newConfiguredDevices: ConfiguredDevice[]) => {
      dispatch({
        type: 'setSelectedStageDevices',
        payload: newConfiguredDevices,
      });
    },
    [dispatch],
  );

  const handleDeckOptionsChange = useCallback(
    (newConfiguredDevice: ConfiguredDevice) => {
      const { configuredDevices: currentDevices = [] } = workflowConfig;
      const index = currentDevices.findIndex(v => v.id === newConfiguredDevice.id);
      if (index > -1) {
        const updatedConfig = {
          ...workflowConfig,
          configuredDevices: currentDevices.toSpliced(index, 1, newConfiguredDevice),
        };
        dispatch({ type: 'setConfig', payload: updatedConfig });
      }
    },
    [dispatch, workflowConfig],
  );

  const onConfigParamChange = useCallback(
    (paramChanged: string, newValues: ParameterValue) => {
      logEvent('edit-config-parameter', ScreenRegistry.WORKFLOW, paramChanged);
      const configWithUpdatedGlobalProps = produce(workflowConfig, draft => {
        // first update global
        draft.global = {
          ...draft.global,
          [paramChanged]: newValues,
        };
        // then propagate the changes in global into the configuredDevices
        const draftWithPopulatedDevices = updateConfigAfterSet(
          draft,
          selectedStage?.configuredDevices,
        );
        draft.configuredDevices = draftWithPopulatedDevices.configuredDevices;
      });

      // We divide the list into two in order to make a two column layout, so
      // it's important to merge the newly changed values (i.e. at most one half
      // of the total set of values) into the existing, full set of values.
      dispatch({ type: 'setConfig', payload: configWithUpdatedGlobalProps });
    },
    [dispatch, selectedStage, workflowConfig],
  );

  const onUploadedPolicyFileChange = useCallback(
    (policyFile: DirectUploadSingleValueLegacy | undefined) => {
      if (!policyFile) {
        // File "changed" but no file.
        // This happens when the user removes the policy file
        // using the little 'x' icon.
        // We take existing config without liguidHandlingPolicyXlsx{File, FileName}
        const {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          liquidHandlingPolicyXlsxJmpFile,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          liquidHandlingPolicyXlsxJmpFileName,
          ...globalMixerMinusLiquidPolicyFile
        } = workflowConfig.global;
        dispatch({
          type: 'setConfig',
          payload: {
            ...workflowConfig,
            global: globalMixerMinusLiquidPolicyFile,
          },
        });
        return;
      }

      const pf = policyFile;
      if (!pf.bytes || !pf.name) {
        throw new Error('Unexpected value for policy upload');
      }
      const newGlobalMixerConfig = {
        ...workflowConfig.global,
        liquidHandlingPolicyXlsxJmpFile: pf.bytes.bytes,
        liquidHandlingPolicyXlsxJmpFileName: pf.name,
      };
      dispatch({
        type: 'setConfig',
        payload: {
          ...workflowConfig,
          global: newGlobalMixerConfig,
        },
      });
    },
    [dispatch, workflowConfig],
  );

  return (
    <Panel
      className={className}
      title="Workflow Settings"
      onClose={onClose}
      panelContent="WorkflowSettings"
    >
      <Box p={3}>
        {/** We need to support legacy workflows with 0 stages for readonly display, and in this case, we won't show stage name */}
        {stages.length > 0 && (
          <div className={classes.nameInputContainer}>
            <TextField
              variant="standard"
              label="Stage name"
              disabled={isReadonly}
              value={stageNameValidationState}
              fullWidth
              onChange={onStageNameChange}
              error={!isStageNameValid(stageNameValidationState)}
              helperText={
                isStageNameValid(stageNameValidationState)
                  ? ' '
                  : 'Stage name must be defined'
              }
            />
          </div>
        )}
        <SettingsPanelContainer
          title="Execution Mode"
          helpContent="Set the workflow to be run manually, via a device, or as a data workflow. This will determine the instructions type and deck settings."
        >
          <DeviceSelectorCard
            onChange={handleSelectedDevicesChange}
            numStages={stages.length}
            isDisabled={isReadonly}
          />
          {showDeckOptions && (
            <div className={classes.deckOptions}>
              <Divider className={classes.divider} />
              <div className={classes.header}>
                <Typography variant="overline" color="textPrimary">
                  Deck Options
                </Typography>
                <IconButtonWithPopper
                  content={
                    <Typography variant="caption">
                      Select a deck layout, then tell Synthace where you want it to
                      allocate your labware. To learn more, click{' '}
                      <Link
                        href="https://intercom.help/antha/en/articles/5588117-deck-options"
                        target="_blank"
                        variant="caption"
                        rel="noopenner noreferrer"
                        underline="hover"
                      >
                        here
                      </Link>
                      .
                    </Typography>
                  }
                  iconButtonProps={{
                    size: 'xsmall',
                    icon: <HelpOutlineIcon />,
                  }}
                  onClick={doNothing} //TODO: Update with logging
                />
              </div>
              <DeckOptionsParameter
                onChange={handleDeckOptionsChange}
                isDisabled={isReadonly}
              />
            </div>
          )}
        </SettingsPanelContainer>
        {requiresDevice && (
          <>
            <DeviceSettingOption
              isDisabled={isReadonly}
              parameter={INPUTPLATES}
              value={paramValueDict[INPUTPLATES.name]}
              onChange={onConfigParamChange}
              helpContent="If you did not prepare your input plates in advance, select the plate types that you want to use for your input plates."
              additionalHelpLinkUrl="https://intercom.help/antha/en/articles/5409800-select-the-plate-types-that-you-want-to-use-for-your-input-plates"
            />
            {showCustomLHPolicies && (
              <>
                <TipTypeSettingOption
                  value={paramValueDict[TIPTYPES.name]}
                  onChange={onConfigParamChange}
                />
                <SettingsPanelContainer
                  title="Custom Liquid Policies"
                  helpContent="If the liquid policies in Synthace do not transfer a liquid in your workflow the way that you want, create a custom liquid policy instead."
                  additionalHelpLinkUrl="https://intercom.help/antha/en/articles/5451980-custom-liquid-policies"
                >
                  <LHPolicyUploadEditor
                    isDisabled={isReadonly}
                    fileName={workflowConfig.global?.liquidHandlingPolicyXlsxJmpFileName}
                    file={workflowConfig.global?.liquidHandlingPolicyXlsxJmpFile}
                    onChange={onUploadedPolicyFileChange}
                  />
                </SettingsPanelContainer>
              </>
            )}
            <DeviceAdvancedOptionsList
              parameterValueDict={workflowConfig.global as ParameterValueDict}
              onChange={onConfigParamChange}
              isDisabled={isReadonly}
            />
          </>
        )}
      </Box>
    </Panel>
  );
});

export default SettingsPanel;

const useStyles = makeStylesHook(theme => ({
  deckOptions: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(3),
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(4, 0),
  },
  nameInputContainer: {
    padding: theme.spacing(3),
  },
}));
