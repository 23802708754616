import React, { useState } from 'react';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import {
  DECK_LOCATION_CSS_CLASS,
  getSmallDeckPositionTooltipStyles,
  SMALL_DECK_POSITION_SIZE,
  useDeckPositionTooltip,
} from 'client/app/apps/workflow-builder/panels/workflow-settings/deck-options/deckOptionsPanelUtils';
import { DeckPositionChip } from 'client/app/apps/workflow-builder/panels/workflow-settings/deck-options/DeckPositionChip';
import {
  areLabwareTypesEqual,
  isNamedPlate,
  LabwareType,
} from 'client/app/state/LabwarePreference';
import doNothing from 'common/lib/doNothing';
import { Dimensions } from 'common/types/Dimensions';
import { Position2d } from 'common/types/Position';
import Colors from 'common/ui/Colors';
import Popover from 'common/ui/components/Popover';
import { DeckPositionRect } from 'common/ui/components/simulation-details/mix/DeckLayout';
import { getPosFromEvent, isDrag } from 'common/ui/lib/ClickRecognizer';

/**
 * DeckPositionWithPreferences renders the position that a labware can be placed along with info
 * regarding whether or not the position is a place where labware preference has been added.
 * This differs from the DeckPositionView where this allows for interactions for labware preferences.
 */

type Props = {
  deckPosition: DeckPositionRect;
  activeLabwareType?: LabwareType;
  selectedLabwareTypes?: LabwareType[];
  validLabwareTypes?: LabwareType[];
  onAddPosition?: (newPosition: string) => void;
  onRemovePosition?: (oldPosition: string) => void;
  isDisabled: boolean;
};

export default React.memo(function DeckPositionWithPreferences({
  deckPosition: { deckPositionName, absolutePosInDeckPixels },
  activeLabwareType,
  selectedLabwareTypes,
  validLabwareTypes,
  onAddPosition = doNothing,
  onRemovePosition = doNothing,
  isDisabled,
}: Props) {
  const isSelectedForCurrentLabware =
    activeLabwareType && selectedLabwareTypes?.includes(activeLabwareType);
  const isNamedPlateDeckPositionSelectable =
    isNamedPlate(activeLabwareType) &&
    validLabwareTypes?.some(t => ['inputPlates', 'outputPlates'].includes(t));
  const isDeckPositionSelectable =
    !isDisabled &&
    activeLabwareType &&
    (validLabwareTypes?.includes(activeLabwareType) ||
      isNamedPlateDeckPositionSelectable);

  const [cursorPosition, setCursorPosition] = useState<Position2d | null>(null);
  const setCurrentCursorPosition = (event: React.PointerEvent) => {
    if (isDeckPositionSelectable) {
      setCursorPosition(getPosFromEvent(event));
    }
  };
  const handleSelection = (event: React.PointerEvent) => {
    // We only want to trigger the addition/removal if the user has clicked
    // and not if they are dragging the workspace canvas.
    if (!isDeckPositionSelectable || isDrag(cursorPosition, event)) return;

    if (isSelectedForCurrentLabware) {
      onRemovePosition(deckPositionName);
    } else {
      onAddPosition(deckPositionName);
    }
  };

  const chipList = (
    <ChipList>
      {isDeckPositionSelectable && activeLabwareType && (
        <DeckPositionChip
          labwareType={activeLabwareType}
          deckPositionName={deckPositionName}
          state={isSelectedForCurrentLabware ? 'active' : 'placeholder'}
        />
      )}
      {selectedLabwareTypes
        ?.filter(v => v !== activeLabwareType)
        ?.map(labware => (
          <DeckPositionChip
            key={`${deckPositionName}_${labware}`}
            labwareType={labware}
            deckPositionName={deckPositionName}
            state={
              areLabwareTypesEqual(activeLabwareType, labware) ? 'active' : 'inactive'
            }
          />
        ))}
    </ChipList>
  );

  const positionLabel = (
    <PositionLabel variant="h5" noWrap title={deckPositionName}>
      {deckPositionName}
    </PositionLabel>
  );

  const checkIcon = isSelectedForCurrentLabware && (
    <CheckIcon color="success" positionDimensions={absolutePosInDeckPixels} />
  );

  const isSmallDeckPosition =
    Math.min(absolutePosInDeckPixels.width, absolutePosInDeckPixels.height) <=
    SMALL_DECK_POSITION_SIZE;

  const tooltipHandle = useDeckPositionTooltip();

  const deckLocationProps = {
    className: DECK_LOCATION_CSS_CLASS,
    style: absolutePosInDeckPixels,
    selectable: isDeckPositionSelectable,
    selected: isSelectedForCurrentLabware,
    wasSelected: (selectedLabwareTypes?.length ?? 0) > 0,
  };

  return isSmallDeckPosition ? (
    <Popover
      open={tooltipHandle.open}
      placement="bottom-start"
      componentsProps={{
        tooltip: {
          sx: getSmallDeckPositionTooltipStyles(isDeckPositionSelectable),
        },
      }}
      title={
        <>
          {chipList}
          {positionLabel}
        </>
      }
    >
      <DeckLocation
        key={deckPositionName}
        {...deckLocationProps}
        onPointerDown={(event: React.PointerEvent) => {
          setCurrentCursorPosition(event);
          tooltipHandle.hide();
          tooltipHandle.lock();
        }}
        onPointerUp={(event: React.PointerEvent) => {
          handleSelection(event);
          tooltipHandle.unlock();
        }}
        onMouseEnter={tooltipHandle.show}
        onMouseLeave={tooltipHandle.hide}
        onWheel={tooltipHandle.hideThrottled}
      >
        <IconContainer>{checkIcon}</IconContainer>
      </DeckLocation>
    </Popover>
  ) : (
    <DeckLocation
      key={deckPositionName}
      {...deckLocationProps}
      onPointerDown={setCurrentCursorPosition}
      onPointerUp={handleSelection}
    >
      <ChipListContainer>{chipList}</ChipListContainer>
      <IconContainer>{checkIcon}</IconContainer>
      <PositionLabelContainer>{positionLabel}</PositionLabelContainer>
    </DeckLocation>
  );
});

// #region Styles

const DeckLocation = styled('main', {
  shouldForwardProp: prop =>
    !(['selectable', 'selected', 'wasSelected'] as PropertyKey[]).includes(prop),
})<{
  selectable?: boolean;
  selected?: boolean;
  wasSelected?: boolean;
}>(({ selectable, selected, wasSelected }) => {
  const selectableStyle = selectable
    ? {
        backgroundColor: Colors.GREY_0,
        '&:hover': {
          cursor: 'pointer',
          border: `2px solid ${Colors.BLUE_50}`,
          backgroundColor: Colors.BLUE_0,
        },
      }
    : selected
    ? {
        backgroundColor: Colors.GREY_0,
      }
    : {
        background: `linear-gradient(to top left,
             ${Colors.GREY_5} 0%,
             ${Colors.GREY_5} calc(50% - 0.8px),
             ${Colors.GREY_30} 50%,
             ${Colors.GREY_5} calc(50% + 0.8px),
             ${Colors.GREY_5} 100%)`,
      };

  const selectedStyle = selected
    ? {
        border: `2px solid ${Colors.BLUE_50}`,
      }
    : null;

  const selectablePositionNotSelectedBeforeStyle =
    selectable && !wasSelected && !selected
      ? {
          border: `2px dashed ${Colors.GREY_30}`,
        }
      : null;

  return {
    position: 'absolute',
    backgroundColor: Colors.GREY_5,
    border: `1px solid ${Colors.GREY_30}`,
    borderRadius: '4px',

    display: 'grid',
    gridTemplateRows: '1fr 1fr 1fr',

    '&:hover': {
      zIndex: 1,
    },

    ...selectableStyle,
    ...selectedStyle,
    ...selectablePositionNotSelectedBeforeStyle,
  };
});

const ChipList = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

const ChipListContainer = styled('section')(({ theme }) => ({
  gridRow: 1,
  alignSelf: 'start',
  padding: theme.spacing(5, 0, 0, 5),
}));

const PositionLabel = styled(Typography)(({ theme }) => {
  return {
    maxWidth: 'fit-content',
    padding: theme.spacing(2, 3),

    backgroundColor: Colors.GREY_20,
    color: theme.palette.text.secondary,

    border: `1px solid ${Colors.GREY_30}`,
    borderRadius: '4px',
    textOverflow: 'ellipsis',
  };
});

const PositionLabelContainer = styled('section')(({ theme }) => ({
  gridRow: 3,
  alignSelf: 'end',
  overflow: 'hidden',
  padding: theme.spacing(0, 0, 5, 5),
}));

const CheckIcon = styled(CheckCircleOutlineIcon, {
  shouldForwardProp: prop => prop !== 'positionDimensions',
})<{ positionDimensions: Dimensions }>(
  ({ theme, positionDimensions: { width, height } }) => {
    const minDimension = Math.min(width, height);
    return {
      alignSelf: 'center',
      fontSize:
        minDimension > SMALL_DECK_POSITION_SIZE
          ? 44
          : minDimension > SMALL_DECK_POSITION_SIZE / 2
          ? 32
          : 16,
      [`[class*=${DECK_LOCATION_CSS_CLASS}]:hover &`]: {
        color: theme.palette.primary.main,
      },
    };
  },
);

const IconContainer = styled('section')({
  gridRow: '2',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

// #endregion
