import React, { useCallback, useMemo } from 'react';

import { useQuery } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

import { deviceFromGraphQL } from 'client/app/api/deviceFromGraphql';
import { QUERY_ALL_DEVICES } from 'client/app/api/gql/queries';
import DeviceItems from 'client/app/apps/workflow-builder/panels/workflow-settings/devices/DeviceItems';
import DeviceSelectorPanel, {
  DEVICE_SELECTOR_PANEL_ID,
} from 'client/app/apps/workflow-builder/panels/workflow-settings/devices/DeviceSelectorPanel';
import { DeviceIcon } from 'client/app/icons';
import { useConfiguredDevicesContext } from 'client/app/state/ConfiguredDevicesProvider/ConfiguredDevicesProvider';
import { ConfiguredDevice, ConfiguredDeviceDeviceId } from 'common/types/bundle';
import {
  addAccessibleDevice,
  removeAccessibleDeviceByDeviceId,
} from 'common/types/bundleConfigUtils';
import { Device, SimpleDevice } from 'common/types/device';
import Button from 'common/ui/components/Button';
import { useAdditionalPanelContext } from 'common/ui/providers/AdditionalPanelProvider';

type Props = {
  onChange: (newConfiguredDevices: ConfiguredDevice[]) => void;
  numStages: number;
  isDisabled?: boolean;
};

/**
 * This component is used for showing the selected devices and a button to open the SelectDevicesPanel
 * to allow the user to select the devices.
 */
export default function DeviceSelectorCard({ onChange, numStages, isDisabled }: Props) {
  const { activeConfiguredDevices, setActiveConfiguredDeviceIds } =
    useConfiguredDevicesContext();
  const { setAdditionalPanel, clearAdditionalPanel, additionalPanelId } =
    useAdditionalPanelContext();

  // We need to fetch all of the devices because the device that we are currently getting from the
  // workflow doesn't have all the info that we need to show the device item (e.g. the image url).
  // Most customers have a small number of devices so it is okay that we are doing a query for all
  // devices.
  const { data, loading } = useQuery(QUERY_ALL_DEVICES);

  const selectedDevices = useMemo<Device[]>(() => {
    const configuredDeviceIds = activeConfiguredDevices.map(cd => cd.deviceId);
    return (
      data?.devices
        .filter(device => configuredDeviceIds.includes(device.id))
        .map(deviceFromGraphQL) ?? []
    );
  }, [activeConfiguredDevices, data?.devices]);

  const handleConfiguredDevicesChange = useCallback(
    (newConfiguredDevices: ConfiguredDevice[]) => {
      setActiveConfiguredDeviceIds(newConfiguredDevices.map(d => d.id));
      onChange(newConfiguredDevices);
    },
    [onChange, setActiveConfiguredDeviceIds],
  );

  const isDeviceSelectorPanelOpen = additionalPanelId === DEVICE_SELECTOR_PANEL_ID;
  const handleToggleDeviceSelectorPanel = useCallback(() => {
    if (isDeviceSelectorPanelOpen) {
      clearAdditionalPanel();
    } else {
      setAdditionalPanel({
        id: DEVICE_SELECTOR_PANEL_ID,
        contents: (
          <DeviceSelectorPanel
            numStages={numStages}
            activeConfiguredDevices={activeConfiguredDevices}
            onChange={handleConfiguredDevicesChange}
            onClose={clearAdditionalPanel}
          />
        ),
      });
    }
  }, [
    isDeviceSelectorPanelOpen,
    clearAdditionalPanel,
    setAdditionalPanel,
    numStages,
    activeConfiguredDevices,
    handleConfiguredDevicesChange,
  ]);

  const handleAccessibleDeviceEnabledChange = useCallback(
    (accessibleDevice: SimpleDevice, isEnabled: boolean) => {
      if (!isEnabled) {
        const newConfiguredDevices = removeAccessibleDeviceByDeviceId(
          activeConfiguredDevices,
          accessibleDevice.id as ConfiguredDeviceDeviceId,
        );
        handleConfiguredDevicesChange(newConfiguredDevices);
      } else {
        const parentDevice = selectedDevices.find(device =>
          device.accessibleDevices.some(a => a.id === accessibleDevice.id),
        );
        if (!parentDevice) {
          // TODO(CI-1299): Decide if we should be logging this in a better way.
          console.error(
            `Trying to enable an accessible device ${accessibleDevice.id} ` +
              'but there is no corresponding parent device in the workflow config. ' +
              'This can only happen if the workflow config is in a bad state due to a bug.',
          );
          return;
        }
        const newConfiguredDevices = addAccessibleDevice(
          activeConfiguredDevices,
          accessibleDevice,
          parentDevice.id,
        );
        handleConfiguredDevicesChange(newConfiguredDevices);
      }
    },
    [activeConfiguredDevices, handleConfiguredDevicesChange, selectedDevices],
  );

  const isEditable = !isDisabled && !isDeviceSelectorPanelOpen;
  const areDevicesSelected = selectedDevices.length > 0;
  const shouldSelectDevice = !areDevicesSelected || isDeviceSelectorPanelOpen;

  return (
    <Stack spacing={2}>
      <DeviceItems
        devices={selectedDevices}
        configuredDevices={activeConfiguredDevices}
        isEditable={isEditable}
        onAccessibleDeviceEnabledChange={
          (isEditable && handleAccessibleDeviceEnabledChange) || undefined
        }
      />
      {!isDisabled && (
        <Button
          sx={{ width: '100%' }}
          color="primary"
          variant="tertiary"
          onClick={handleToggleDeviceSelectorPanel}
          size="small"
          startIcon={loading ? <CircularProgress size={18} /> : <DeviceIcon />}
        >
          {`${shouldSelectDevice ? 'Select' : 'Change'} Execution Mode`}
        </Button>
      )}
    </Stack>
  );
}
