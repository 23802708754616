import React, { createContext, PropsWithChildren, useContext, useState } from 'react';

import { TabsInfo } from 'common/ui/components/Tabs';

export enum ViewTab {
  INPUTS,
  OUTPUTS,
  STAGES,
}

export const TABS: TabsInfo<ViewTab> = [
  { value: ViewTab.INPUTS, label: 'Parameters' },
  { value: ViewTab.OUTPUTS, label: 'Outputs' },
  { value: ViewTab.STAGES, label: 'Devices' },
];

export const MIN_TAB_WIDTH = '94px';

type StepTabViewContextType = {
  activeTab: ViewTab;
  inputTabVisible: boolean;
  outputTabVisible: boolean;
  stageTabVisible: boolean;
  handleTabChange: (t: ViewTab) => void;
};

const StepTabViewContext = createContext<StepTabViewContextType | undefined>(undefined);

export const useStepTabViewContext = () => {
  const context = useContext(StepTabViewContext);
  if (context === undefined) {
    throw new Error('useStepTabViewContext must be used within a StepTabViewProvider');
  }
  return context;
};

export function StepTabViewProvider({ children }: PropsWithChildren) {
  const [tabId, setTabId] = useState(ViewTab.INPUTS);

  const handleTabChange = (id: ViewTab) => {
    setTabId(id);
  };

  const inputTabVisible = tabId === ViewTab.INPUTS;
  const outputTabVisible = tabId === ViewTab.OUTPUTS;
  const stageTabVisible = tabId === ViewTab.STAGES;

  const state = {
    activeTab: tabId,
    inputTabVisible,
    outputTabVisible,
    stageTabVisible,
    handleTabChange,
  };

  return (
    <StepTabViewContext.Provider value={state}>{children}</StepTabViewContext.Provider>
  );
}
