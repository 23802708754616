import React, { useCallback, useState } from 'react';

import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { sanitize } from 'dompurify';

import {
  fromNamedPlate,
  getNamedPlates,
  NamedPlate,
  toNamedPlate,
} from 'client/app/state/LabwarePreference';
import { useWorkflowBuilderSelector } from 'client/app/state/WorkflowBuilderStateContext';
import Colors from 'common/ui/Colors';
import IconButton from 'common/ui/components/IconButton';
import { useEnterKeyPress } from 'common/ui/hooks/useEnterKeyPress';
import useTextFieldChange from 'common/ui/hooks/useTextFieldChange';

type Props = {
  /** The named plate to which the popover applies. */
  namedPlate: NamedPlate;
  onDelete: (name: NamedPlate) => void;
  onRename: (oldName: NamedPlate, newName: NamedPlate) => void;
  onClose: () => void;
};

export const NamedPlatesSelectorPopover = React.memo(function NamedPlatesSelectorPopover({
  namedPlate,
  onDelete,
  onRename,
  onClose,
}: Props) {
  const { namedPlates } = useWorkflowBuilderSelector(state => ({
    namedPlates: getNamedPlates(state),
  }));

  // We have to strip the prefix when showing the name to the user
  const nonPrefixedNamedPlate = fromNamedPlate(namedPlate);
  const [updatedPlateName, setUpdatedPlateName] = useState(nonPrefixedNamedPlate);
  const onTextFieldChange = useTextFieldChange(setUpdatedPlateName);

  const isNewNameUnique = isNewPlateNameValid(namedPlates, updatedPlateName);
  const isNewNameNotEmpty = updatedPlateName.length > 0;
  const isNewNameSameAsOldName = nonPrefixedNamedPlate === updatedPlateName;

  const canSaveName = isNewNameSameAsOldName || (isNewNameUnique && isNewNameNotEmpty);

  let helperText = '';
  if (!isNewNameSameAsOldName && !isNewNameUnique) {
    helperText = 'Prefix already exists';
  }
  if (!isNewNameSameAsOldName && !isNewNameNotEmpty) {
    helperText = 'Prefix cannot be empty';
  }

  const handleNameChange = useCallback(() => {
    if (!canSaveName) {
      return;
    }
    onRename(namedPlate, toNamedPlate(sanitisePlateName(updatedPlateName)));
    onClose();
  }, [canSaveName, namedPlate, onClose, onRename, updatedPlateName]);

  const handleDeleteNamedPlate = useCallback(() => {
    onDelete(namedPlate);
    onClose();
  }, [namedPlate, onClose, onDelete]);

  const onKeyPress = useEnterKeyPress(handleNameChange);

  return (
    <StyledTextField
      autoFocus
      error={!canSaveName}
      helperText={helperText}
      fullWidth
      onChange={onTextFieldChange}
      onKeyDown={onKeyPress}
      value={updatedPlateName}
      size="small"
      variant="standard"
      InputProps={{
        disableUnderline: true,
        endAdornment: (
          <IconButton
            icon={<DeleteOutlinedIcon htmlColor={Colors.TEXT_PRIMARY} />}
            onClick={handleDeleteNamedPlate}
            size="small"
          />
        ),
        sx: theme => ({
          input: {
            backgroundColor: Colors.ACTION_PRIMARY_MAIN_HOVER,
            padding: theme.spacing(1, 2),
            border: canSaveName ? 'none' : `1px solid ${Colors.ERROR_MAIN}`,
          },
        }),
      }}
    />
  );
});

const StyledTextField = styled(TextField)(({ theme }) => ({
  minWidth: '278px',
  padding: theme.spacing(2, 2, 2, 3),
}));

// Returns false if one of the namedPlates already has a name identical to the query
// or if query is an invalid entry (length is zero, or contains only whitespace).
// This check is case sensitive and will remove whitespace from the query before comparing.
function isNewPlateNameValid(namedPlates: NamedPlate[], query: string = '') {
  return (
    query.trim().length > 0 && // query.trim().length checks for appended or prepended whitespace
    !namedPlates.map(plate => fromNamedPlate(plate)).some(plate => plate === query.trim())
  );
}

// Trims trailing whitespace from the name.
function sanitisePlateName(name: string = '') {
  return sanitize(name.trim());
}
