import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useQuery } from '@apollo/client';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import ButtonBase from '@mui/material/ButtonBase';
import { alpha, styled } from '@mui/material/styles';

import { QUERY_ALL_DEVICES } from 'client/app/api/gql/queries';
import { getSelectedMainDevice } from 'client/app/apps/workflow-builder/panels/workflow-settings/deck-options/deckOptionsPanelUtils';
import StageCard, { STAGE_CARD_WIDTH } from 'client/app/apps/workflow-builder/StageCard';
import { DeviceCommonFragment } from 'client/app/gql';
import { useConfiguredDevicesContext } from 'client/app/state/ConfiguredDevicesProvider/ConfiguredDevicesProvider';
import {
  useWorkflowBuilderDispatch,
  useWorkflowBuilderSelector,
} from 'client/app/state/WorkflowBuilderStateContext';
import { DATA_ONLY_DUMMY_DEVICE } from 'common/constants/manual-device';
import { Stage } from 'common/types/bundle';
import { hasLiquidHandler } from 'common/types/bundleConfigUtils';
import Colors from 'common/ui/Colors';

const TIMELINE_CONTAINER_ID = 'builder-timeline-container';
export const TIMELINE_CONTAINER_STAGES_GAP = 16;

type TimeLineProps = {
  disabled?: boolean;
};

export default function TimeLine(props: TimeLineProps) {
  const { disabled } = props;
  const { setActiveConfiguredDeviceIds } = useConfiguredDevicesContext();
  const { stages, selectedStageId, config, mode } = useWorkflowBuilderSelector(
    state => state,
  );
  const dispatch = useWorkflowBuilderDispatch();

  // This is run on workflow validation so should already be cached
  const { data } = useQuery(QUERY_ALL_DEVICES);
  const deviceData = useMemo(() => {
    const deviceMap = new Map<string, DeviceCommonFragment>();

    data?.devices.forEach(device => {
      if (!deviceMap.has(device.id)) {
        deviceMap.set(device.id, device);
      }
    });

    return deviceMap;
  }, [data?.devices]);

  const handleSelectStage = (id: string | undefined) => {
    const stage = stages.find(stage => stage.id === id);
    const payloadId = id === selectedStageId ? undefined : id;
    dispatch({
      type: 'setSelectedStageId',
      payload: { id: payloadId, isThroughTimeline: true },
    });
    setActiveConfiguredDeviceIds(stage?.configuredDevices || []);
  };

  const stagesRefs = useRef<(HTMLDivElement | null)[]>([]);
  const currentlyVisibleStages = useRef<Set<Element>>(new Set());

  const containerRef = useRef<HTMLDivElement | null>(null);
  const prevLastStageId = useRef<string | undefined>();

  const [firstStageId, lastStageId] = useMemo(() => {
    const firstStageId = stages.length > 0 ? stages[0].id : undefined;
    const lastStageId = stages.length > 1 ? stages[stages.length - 1].id : firstStageId;
    return [firstStageId, lastStageId];
  }, [stages]);

  const [overflow, setOverflow] = useState<{ left: boolean; right: boolean }>({
    left: false,
    right: false,
  });

  // Scroll new stages into view when they are added.
  useEffect(() => {
    if (
      lastStageId !== undefined &&
      prevLastStageId.current !== undefined &&
      prevLastStageId.current !== lastStageId &&
      stagesRefs.current?.length
    ) {
      stagesRefs.current[stagesRefs.current.length - 1]?.scrollIntoView({
        behavior: 'smooth',
      });
    }

    prevLastStageId.current = lastStageId;
  }, [lastStageId]);

  useEffect(() => {
    const rootDoc = document.getElementById(TIMELINE_CONTAINER_ID);
    let observer: IntersectionObserver | null = null;
    if (rootDoc) {
      observer = new IntersectionObserver(
        entries => {
          for (const entry of entries) {
            entry.isIntersecting
              ? currentlyVisibleStages.current.add(entry.target)
              : currentlyVisibleStages.current.delete(entry.target);
          }

          const stageIdOrder = stages.map(stage => stage.id);
          currentlyVisibleStages.current = new Set(
            [...currentlyVisibleStages.current.values()]
              // This is a precaution to clear out elements that no longer existing the DOM.
              // It shouldn't happen in production, but can happen in dev with hot-loading.
              .filter(n => document.contains(n))
              // Here we are sorting the entries in order of the stages so we can correctly
              // scroll to the correct item.
              .sort((a, b) => stageIdOrder.indexOf(a.id) - stageIdOrder.indexOf(b.id)),
          );

          const currentlyVisibleStagesElements = [
            ...currentlyVisibleStages.current.values(),
          ];
          const leftMostStage = currentlyVisibleStagesElements.find(
            e => e.id === firstStageId,
          );
          const rightMostStage = currentlyVisibleStagesElements.find(
            e => e.id === lastStageId,
          );

          setOverflow(prev => {
            if (!firstStageId || !lastStageId || !entries[0].rootBounds) {
              return prev;
            }

            // Here we are checking the position of the leftmost or rightmost stage in relation
            // to the root bounds of the observer. If either of these are not present in
            // currentlyVisibleStagesElements then there must be an overflow.
            // We monitor the position of these elements to determine when they come into full
            // view in the IntersectionObserver (i.e. for left, the leftmost stage 'left' value is less than
            // the 'left' root element value), at which point, there will be no overflow.
            const { left: rootLeft, right: rootRight } = entries[0].rootBounds;
            let leftOverflow = !leftMostStage;
            let rightOverflow = !rightMostStage;

            if (leftMostStage) {
              const { left: firstLeft } = leftMostStage.getBoundingClientRect();
              leftOverflow = firstLeft < rootLeft;
            }

            if (rightMostStage) {
              const { right: lastRight } = rightMostStage.getBoundingClientRect();
              rightOverflow = lastRight > rootRight;
            }

            return {
              left: leftOverflow,
              right: rightOverflow,
            };
          });
        },
        {
          root: rootDoc,
          threshold: [0, 0.25, 0.5, 0.75, 1],
          rootMargin:
            rootDoc.clientWidth <= STAGE_CARD_WIDTH
              ? `${STAGE_CARD_WIDTH}px`
              : `${TIMELINE_CONTAINER_STAGES_GAP}px`, // Handle when root is small width, to ensure we extend rootMargin to capture other entires for the observer
        },
      );
      stagesRefs.current.forEach(step => {
        if (step) {
          observer?.observe(step);
        }
      });
    }
    return () => observer?.disconnect();
  }, [currentlyVisibleStages, firstStageId, lastStageId, stages]);

  // There is an issue trying to call scrollIntoView with behaviour: 'smooth' on an element
  // when the button that triggers this click event turns into a disabled state during the scroll animation.
  // This happens for us if we try and call scrollIntoView on the first or last element and
  // update overview state to disable the buttons. To overcome this, when we are scrolling
  // to the first or last element, we can just scroll to the start/end of the container itself.
  //
  // See https://github.com/facebook/react/issues/20770
  //
  const onClickLeft = () => {
    if (currentlyVisibleStages.current) {
      const firstVisibleElement = [...currentlyVisibleStages.current.values()]?.[0];
      if (firstVisibleElement.id === firstStageId) {
        containerRef.current?.scrollTo({
          left: 0,
          behavior: 'smooth',
        });
      } else {
        firstVisibleElement?.scrollIntoView({
          behavior: 'smooth',
        });
      }
    }
  };

  const onClickRight = () => {
    if (currentlyVisibleStages.current) {
      const lastVisibleElement = [...currentlyVisibleStages.current.values()]?.[
        currentlyVisibleStages.current.size - 1
      ];
      if (lastVisibleElement.id === lastStageId) {
        containerRef.current?.scrollTo({
          left: containerRef.current.scrollWidth,
          behavior: 'smooth',
        });
      } else {
        lastVisibleElement?.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  const getDeviceInfo = useCallback(
    (stage: Stage) => {
      const configuredDevices = config.configuredDevices?.filter(configuredDevice =>
        stage.configuredDevices.includes(configuredDevice.id),
      );
      const dummyDeviceImage = DATA_ONLY_DUMMY_DEVICE.imageUrl!; // This field is specified in a const and is not null
      const dummyDeviceType = DATA_ONLY_DUMMY_DEVICE.model;
      if (!configuredDevices || !data?.devices) {
        return {
          // We fall back to data-only in cases where devices are not found (e.g. if they are deleted)
          deviceImageURL: dummyDeviceImage,
          deviceType: dummyDeviceType,
        };
      }
      const { selectedDeviceCommon } = getSelectedMainDevice(
        configuredDevices,
        data.devices,
      );

      let device: DeviceCommonFragment | undefined = selectedDeviceCommon;
      if (!selectedDeviceCommon && configuredDevices.length === 1) {
        // In this case, no main liquid handling device is found, but a device is present.
        // This would be an analytical device, so we show info for that device
        device = deviceData.get(configuredDevices[0].deviceId);
      }
      return {
        deviceImageURL: device?.model.pictureURL ?? dummyDeviceImage,
        deviceType: device?.model.anthaModel ?? dummyDeviceType,
      };
    },
    [config.configuredDevices, data?.devices, deviceData],
  );

  const isLiquidHandler = useMemo(() => hasLiquidHandler(config), [config]);

  return (
    <Container>
      <LeftButtons>
        <TimeLineButton
          icon={<KeyboardArrowLeftOutlinedIcon />}
          onClick={onClickLeft}
          disabled={!overflow.left}
          overflow={overflow.left ? 'left' : undefined}
        />
      </LeftButtons>
      <Content id={TIMELINE_CONTAINER_ID} ref={containerRef}>
        {stages.map((stage, idx) => {
          const { deviceImageURL, deviceType } = getDeviceInfo(stage);
          return (
            <StageCard
              ref={element => (stagesRefs.current[idx] = element)}
              key={stage.id}
              id={stage.id}
              name={stage.name}
              index={idx}
              deviceImageURL={deviceImageURL}
              deviceType={deviceType}
              selected={stage.id === selectedStageId}
              onSelect={handleSelectStage}
              disabled={disabled}
              isOnlyStage={stages.length === 1}
            />
          );
        })}
      </Content>
      <RightButtons>
        <TimeLineButton
          icon={<KeyboardArrowRightOutlinedIcon />}
          onClick={onClickRight}
          disabled={!overflow.right}
          overflow={overflow.right ? 'right' : undefined}
        />
        <TimeLineButton
          icon={<AddOutlinedIcon />}
          onClick={() => {
            dispatch({
              type: 'addNewStage',
            });
          }}
          disabled={disabled || isLiquidHandler || mode === 'DOE'}
        />
      </RightButtons>
    </Container>
  );
}
type TimeLineButtonOverflow = 'left' | 'right';

type TimeLineButtonProps = {
  icon: React.ReactElement;
  onClick: () => void;
  className?: string;
  disabled?: boolean;
  overflow?: TimeLineButtonOverflow;
};

function TimeLineButton(props: TimeLineButtonProps) {
  const { icon, onClick, className, disabled, overflow } = props;
  return (
    <StyledButtonBase
      disabled={disabled}
      onClick={onClick}
      className={className}
      overflow={overflow}
    >
      {icon}
    </StyledButtonBase>
  );
}

const Container = styled('div')({
  height: '68px',
  minHeight: '68px',
  display: 'grid',
  gridTemplateColumns: '[left] 68px [content] 1fr [right] 120px',
  backgroundColor: Colors.GREY_0,
  borderTop: `1px solid ${Colors.GREY_30}`,
  scrollBehavior: 'smooth',
});

const StyledButtonBase = styled(ButtonBase, {
  shouldForwardProp: propName => propName !== 'overflow',
})<{ overflow?: TimeLineButtonOverflow }>(({ overflow, theme }) => {
  const boxShadow =
    overflow === 'left'
      ? `2px 0px 4px 0px ${alpha(theme.palette.text.primary, 0.25)}`
      : overflow === 'right'
      ? `-2px 0px 4px 0px ${alpha(theme.palette.text.primary, 0.25)}`
      : 'none';
  const clipPath =
    overflow === 'left'
      ? `inset(0px -10px 0px 0px)`
      : overflow === 'right'
      ? `inset(0px 0px 0px -10px)`
      : 'none';
  return {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: boxShadow,
    clipPath: clipPath,
    transition: 'box-shadow 0.2s',
    '&.Mui-disabled': {
      boxShadow: 'none',
      clipPath: 'none',
      color: theme.palette.text.disabled,
    },
  };
});

const LeftButtons = styled('div')({
  gridColumn: 'left',
  borderRight: `1px solid ${Colors.GREY_30}`,
});

const RightButtons = styled('div')({
  gridColumn: 'right',
  display: 'flex',
  borderLeft: `1px solid ${Colors.GREY_30}`,
});

const Content = styled('div')(({ theme }) => ({
  gridColumn: 'content',
  display: 'flex',
  padding: theme.spacing(3, 0),
  gap: `${TIMELINE_CONTAINER_STAGES_GAP}px`,
  overflowY: 'hidden',
  overflowX: 'auto',
}));
