import React, { useCallback, useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import { deviceFromGraphQL } from 'client/app/api/deviceFromGraphql';
import { QUERY_ALL_DEVICES } from 'client/app/api/gql/queries';
import { PanelWithoutScroll } from 'client/app/apps/workflow-builder/panels/Panel';
import DeviceLibrary from 'client/app/components/DeviceLibrary/DeviceLibrary';
import { DeviceCommonFragment as DeviceCommon } from 'client/app/gql';
import {
  buildConfiguredDevice,
  removeMissingDeviceFromDeviceConfiguration,
} from 'client/app/lib/workflow/deviceConfigUtils';
import { DATA_ONLY_DUMMY_DEVICE } from 'common/constants/manual-device';
import { indexBy } from 'common/lib/data';
import { ConfiguredDevice, ConfiguredDeviceId } from 'common/types/bundle';
import {
  GenericDeviceType,
  getGenericDeviceType,
  getGenericDeviceTypeFromAnthaClass,
  isDeviceThatCanPerformLiquidHandling,
  removeAccessibleDeviceByConfiguredDeviceID,
} from 'common/types/bundleConfigUtils';
import { Device } from 'common/types/device';
import Button from 'common/ui/components/Button';
import SimpleDialog from 'common/ui/components/Dialog/SimpleDialog';
import GraphQLErrorPanel from 'common/ui/components/GraphQLErrorPanel';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useDialog, { DialogProps } from 'common/ui/hooks/useDialog';

export const DEVICE_SELECTOR_PANEL_ID = 'DeviceSelector';

type DeviceSelectorPanelProps = {
  numStages: number;
  activeConfiguredDevices: ConfiguredDevice[];
  onChange: (newConfiguredDevices: ConfiguredDevice[]) => void;
  onClose: () => void;
};

export type DeviceDisabledReason = {
  disabled: boolean;
  reason?: string;
  disabledButtonCopy?: string;
};

/**
 * Panel that allows you to search for devices + select device(s).
 */
export default React.memo(function DeviceSelectorPanel(props: DeviceSelectorPanelProps) {
  const classes = useStyles();
  const { numStages, activeConfiguredDevices, onChange, onClose } = props;

  const { loading, data, error, refetch } = useQuery(QUERY_ALL_DEVICES);
  const devices = useMemo(() => data?.devices || [], [data?.devices]);
  const inputPlateTypes = activeConfiguredDevices.flatMap(d => d.inputPlateTypes ?? []);
  const [selectedDevices, setSelectedDevices] = useState<ConfiguredDevice[]>(
    removeMissingDeviceFromDeviceConfiguration(activeConfiguredDevices, devices),
  );
  const selectedDeviceByDeviceId = useMemo(
    () => indexBy(selectedDevices, 'deviceId'),
    [selectedDevices],
  );
  const selectedDeviceTypes = useMemo(() => {
    return new Set(selectedDevices.map(device => getGenericDeviceType(device.type)));
  }, [selectedDevices]);

  const [
    confirmAccessibleDevicesDisabledDialog,
    openConfirmAccessibleDevicesDisabledDialog,
  ] = useDialog(ConfirmAccessibleDevicesDisabled);

  const isDeviceDisabled = useCallback(
    (device: Device | DeviceCommon): DeviceDisabledReason => {
      return checkIfDeviceIsDisabled(
        device,
        selectedDeviceByDeviceId,
        numStages,
        selectedDeviceTypes,
      );
    },
    [numStages, selectedDeviceByDeviceId, selectedDeviceTypes],
  );

  const handleSelect = useCallback(
    (id: string) => {
      const deviceInfo = devices.find(device => device.id === id);
      if (deviceInfo && isDeviceDisabled(deviceFromGraphQL(deviceInfo)).disabled) {
        return;
      }

      const previouslySelected = selectedDevices.find(cd => cd.deviceId === id);

      let updatedSelectedDevices: ConfiguredDevice[];

      if (!previouslySelected) {
        if (deviceInfo) {
          // Select new automation devices
          const { configuredDevices: selectedDeviceConfig, skippedAccessibleDevices } =
            buildConfiguredDevice([deviceInfo], undefined, selectedDevices);

          updatedSelectedDevices = selectedDeviceConfig.map(device => {
            if (
              inputPlateTypes &&
              (isDeviceThatCanPerformLiquidHandling(device) ||
                getGenericDeviceType(device.type) === 'Unknown')
            ) {
              // persist input plate types since re-selecting them is a
              // non-trivial task even if some are invalid for the new device
              return { ...device, inputPlateTypes };
            }
            return device;
          });

          if (skippedAccessibleDevices) {
            void openConfirmAccessibleDevicesDisabledDialog({});
          }
        } else {
          // Select Data-only and unselect all other devices. Must select this
          // so the card appears selected in device library
          updatedSelectedDevices = [
            {
              id: DATA_ONLY_DUMMY_DEVICE.id as ConfiguredDeviceId,
              deviceId: DATA_ONLY_DUMMY_DEVICE.id as DeviceId,
              type: 'DataOnly',
            },
          ];
        }
      } else {
        // The deselected device might be an accessible device, so try removing it.
        updatedSelectedDevices = removeAccessibleDeviceByConfiguredDeviceID(
          selectedDevices,
          previouslySelected.id,
        );

        // Unselect a device and its accessible devices if the id matches.
        updatedSelectedDevices = updatedSelectedDevices.filter(
          cd =>
            !(
              cd.deviceId === id ||
              previouslySelected.accessibleDeviceConfigurationIds?.includes(cd.id)
            ),
        );
      }
      setSelectedDevices(updatedSelectedDevices);
    },
    [
      devices,
      isDeviceDisabled,
      selectedDevices,
      inputPlateTypes,
      openConfirmAccessibleDevicesDisabledDialog,
    ],
  );

  const handleClear = () => setSelectedDevices([]);

  const handleSave = async () => {
    onChange(selectedDevices);
    // once we update DeckOptionsPanel to use AdditionalPanelProvider, update
    // this to potentially navigate there.
    onClose();
  };

  const selectedDeviceIds = Object.keys(selectedDeviceByDeviceId);

  let panelContent;
  if (error) {
    panelContent = <GraphQLErrorPanel error={error} onRetry={refetch} />;
  } else if (loading) {
    panelContent = <CircularProgress />;
  } else if (devices.length === 0) {
    panelContent = <Typography variant="h5"> No devices found.</Typography>;
  } else {
    panelContent = (
      <DeviceLibrary
        isLoading={loading}
        onSelect={handleSelect}
        devices={devices}
        selectedDeviceIds={selectedDeviceIds}
        isDeviceDisabled={isDeviceDisabled}
        showSelectionStatus
        showManualDeviceRelatedCards
        smallCard
        dialog
        clearSelectionProps={{
          selectedDeviceCount: selectedDeviceIds.length,
          totalDeviceCount: devices.length,
          onClear: handleClear,
        }}
      />
    );
  }

  return (
    <PanelWithoutScroll
      title="Execution Mode"
      onClose={onClose}
      panelContent="DeviceSelector"
      fullWidth
      panelActions={
        <div className={classes.actions}>
          <Button
            className={classes.rightAlign}
            onClick={handleSave}
            variant="tertiary"
            color="primary"
          >
            {selectedDeviceIds.length > 0 ? 'Next' : 'Save'}
          </Button>
        </div>
      }
    >
      {panelContent}
      {confirmAccessibleDevicesDisabledDialog}
    </PanelWithoutScroll>
  );
});

export function checkIfDeviceIsDisabled(
  device: DeviceCommon | Device,
  selectedDeviceByDeviceId: Record<string, ConfiguredDevice>,
  numStages: number,
  selectedDeviceTypes: Set<GenericDeviceType>,
) {
  let anthaClass: string;

  if ('anthaLangDeviceClass' in device) {
    anthaClass = device.anthaLangDeviceClass;
  } else {
    anthaClass = device.model.anthaLangDeviceClass;
  }

  if (selectedDeviceByDeviceId[device.id]) {
    return { disabled: false };
  }

  const deviceType = getGenericDeviceTypeFromAnthaClass(anthaClass);

  if (deviceType === 'Unknown') {
    return {
      disabled: true,
      reason: 'This device cannot be used in a workflow',
    };
  }

  const isDispenser = deviceType === 'Dispenser';
  const isLiquidHandler = deviceType === 'LiquidHandler';
  const isPlateReader = deviceType === 'PlateReader';
  const isPlateWasher = deviceType === 'PlateWasher';
  const isDataOnly = deviceType === 'DataOnly';
  const isManual = deviceType === 'Manual';

  if (selectedDeviceTypes.has('DataOnly')) {
    return {
      disabled: true,
      reason:
        'Data-only (Computational) mode cannot be used in conjunction with any other execution mode',
    };
  }

  if (isDataOnly && selectedDeviceTypes.size > 0) {
    return {
      disabled: true,
      reason:
        'Data-only (Computational) mode cannot be used in conjunction with any other execution mode',
    };
  }

  if (numStages > 1 && (isLiquidHandler || isDataOnly)) {
    return {
      disabled: true,
      reason: 'This device cannot be used in multi-stage workflows',
      disabledButtonCopy: 'Unavailable',
    };
  }

  if (isPlateWasher && selectedDeviceTypes.has('PlateWasher')) {
    return {
      disabled: true,
      reason: `Only one plate washer can be selected for a single stage`,
    };
  }

  if (isPlateReader && selectedDeviceTypes.has('PlateReader')) {
    return {
      disabled: true,
      reason: `Only one plate reader can be selected for a single stage`,
    };
  }

  if (
    (isDispenser || isLiquidHandler || isManual) &&
    (selectedDeviceTypes.has('LiquidHandler') ||
      selectedDeviceTypes.has('Dispenser') ||
      selectedDeviceTypes.has('Manual'))
  ) {
    return {
      disabled: true,
      reason: selectedDeviceTypes.has('Manual')
        ? 'Manual mode cannot be used in conjunction with liquid handlers or dispensers'
        : 'Only one liquid handler or dispenser can be selected at a time',
    };
  }

  if (isPlateReader || isPlateWasher) {
    const hasDispenser = selectedDeviceTypes.has('Dispenser');
    const hasManual = selectedDeviceTypes.has('Manual');
    if (hasDispenser || hasManual) {
      const deviceCopy = isPlateReader ? 'Plate readers' : 'Plate washers';
      const selectedDeviceCopy = hasDispenser ? 'a dispenser' : 'manual mode';
      return {
        disabled: true,
        reason: `${deviceCopy} cannot be used in conjunction with ${selectedDeviceCopy} in the same stage. Please create a separate stage to use this device`,
      };
    }
  }

  if (isDispenser || isManual) {
    const hasPlateReader = selectedDeviceTypes.has('PlateReader');
    const hasPlateWasher = selectedDeviceTypes.has('PlateWasher');
    if (hasPlateReader || hasPlateWasher) {
      const deviceCopy = isDispenser ? 'A dispenser' : 'Manual mode';
      const selectedDeviceCopy = hasPlateReader ? 'plate readers' : 'plate washers';
      return {
        disabled: true,
        reason: `${deviceCopy} cannot be used in conjunction with ${selectedDeviceCopy} in the same stage. Please create a separate stage to use this device`,
      };
    }
  }

  return { disabled: false };
}

function ConfirmAccessibleDevicesDisabled(props: DialogProps<void>) {
  return (
    <SimpleDialog
      title="Notification"
      contentText="Some accessible devices were disabled as another device of the same type has already been selected."
      submitButtonLabel="OK"
      onSubmit={props.onClose}
      hideCancel
      {...props}
    />
  );
}

const useStyles = makeStylesHook({
  actions: {
    display: 'flex',
  },
  rightAlign: {
    marginLeft: 'auto',
  },
});
