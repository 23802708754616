import React, { useCallback, useMemo } from 'react';

import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { DEFAULT_LAYOUT_LABEL } from 'client/app/apps/workflow-builder/panels/workflow-settings/deck-options/deckOptionsPanelUtils';
import { DeviceConfigOption } from 'client/app/state/ConfiguredDevicesProvider/configurationOptions';
import Colors from 'common/ui/Colors';
import Dropdown, { Option } from 'common/ui/filaments/Dropdown';

type Props = {
  selectedOption?: Partial<DeviceConfigOption>;
  availableOptions: DeviceConfigOption[];
  onChange: (option?: DeviceConfigOption) => void;
  isDisabled: boolean;
  loading: boolean;
};

export function DeckLayoutSelector({
  selectedOption,
  availableOptions,
  onChange,
  isDisabled,
  loading,
}: Props) {
  const dropdownOptions = useMemo<Option<DeviceConfigOption>[]>(() => {
    return availableOptions.map(runConfiguration => {
      return {
        label: runConfiguration.label,
        value: runConfiguration,
      };
    });
  }, [availableOptions]);

  const selectedLabel = (selectedOption?.label ?? '').replace(DEFAULT_LAYOUT_LABEL, '');

  const renderLabel = useCallback((option: Option<DeviceConfigOption>) => {
    return (
      <Stack direction="row" alignItems="center">
        <Typography variant="subtitle2">{option.value.label}</Typography>
        {option.value.lastModifiedAt && (
          <Typography variant="body1">
            &nbsp;{`- Updated ${option.value.lastModifiedAt.format('ll')}`}
          </Typography>
        )}
      </Stack>
    );
  }, []);

  const renderValue = useCallback((value: any) => value, []);

  if (dropdownOptions.length === 0) {
    return (
      <Typography noWrap variant="subtitle2">
        {loading
          ? 'Loading...'
          : selectedOption?.label
          ? selectedOption.label
          : 'No options'}
      </Typography>
    );
  }

  return (
    <StyledDropdown
      valueLabel={selectedLabel}
      options={dropdownOptions}
      renderLabel={renderLabel}
      renderValue={renderValue}
      onChange={onChange}
      placeholder="Deck Layout"
      isDisabled={isDisabled}
      disableUnderline
      variant="standard"
      size="medium"
    />
  );
}

const StyledDropdown = styled(Dropdown<DeviceConfigOption>)(({ theme }) => ({
  backgroundColor: Colors.GREY_0,
  border: `1px solid ${Colors.GREY_30}`,
  borderRadius: '4px',
  marginRight: theme.spacing(3),
  padding: theme.spacing(0, 4),
  width: '220px',
  '& .MuiInputBase-input:focus': {
    backgroundColor: Colors.GREY_0,
  },
  '& .MuiSelect-icon': {
    color: Colors.TEXT_PRIMARY,
    marginRight: theme.spacing(3),
  },
}));
