import { useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';
import { tooltipClasses } from '@mui/material/Tooltip';

import {
  QUERY_ALL_DEVICES,
  QUERY_DEVICE_CONFIG_RESPONSE,
} from 'client/app/api/gql/queries';
import { DeviceCommonFragment as DeviceCommon } from 'client/app/gql';
import {
  isNamedPlate,
  LabwareType,
  SimpleLabwareType,
} from 'client/app/state/LabwarePreference';
import { useWorkflowBuilderSelector } from 'client/app/state/WorkflowBuilderStateContext';
import { ConfiguredDevice } from 'common/types/bundle';
import { isDeviceThatCanPerformLiquidHandling } from 'common/types/bundleConfigUtils';
import Colors from 'common/ui/Colors';
import { usePopover } from 'common/ui/hooks/usePopover';
import useThrottle from 'common/ui/hooks/useThrottle';

export const DEFAULT_LAYOUT_LABEL = 'Default Layout';
export const DECK_LOCATION_CSS_CLASS = 'deckLocation';
export const SMALL_DECK_POSITION_SIZE = 100;

/*
 * We need to determine which device is the main device (which is assumed to be one liquid handler)
 * Currently we are assuming it is the main device if there is only one device selected, if the device is a Gilson,
 * has a run config already, has accessible device ids, or isLiquidHandlingDevice().
 *
 * We will be enforcing that you can only have one liquid handler device before you get to picking the run config.
 * Note: Device will always be selected first.
 */
export function getSelectedMainDevice(
  configuredDevices: ConfiguredDevice[],
  devices: DeviceCommon[],
) {
  if (configuredDevices.length === 1) {
    const selectedDevice = configuredDevices[0];

    if (isDeviceThatCanPerformLiquidHandling(selectedDevice)) {
      return {
        selectedDevice,
        selectedDeviceCommon: devices.find(
          device => device.id === selectedDevice.deviceId,
        ),
      };
    }
  } else {
    for (const configuredDevice of configuredDevices) {
      if (
        configuredDevice.runConfigId ||
        configuredDevice.accessibleDeviceConfigurationIds ||
        isDeviceThatCanPerformLiquidHandling(configuredDevice)
      ) {
        return {
          selectedDevice: configuredDevice,
          selectedDeviceCommon: devices.find(
            device => device.id === configuredDevice.deviceId,
          ),
        };
      }
    }
  }

  // happens when the user clears or deselects all devices
  return {
    selectedDevice: undefined,
    selectedDeviceCommon: undefined,
  };
}

/**
 * Returns the devices in the workflow in the format of DeviceCommon[].
 */
export function useGetDeviceCommonForWorkflow(
  configuredDevices: ConfiguredDevice[],
): DeviceCommon[] {
  const { data } = useQuery(QUERY_ALL_DEVICES);
  const deviceIds = configuredDevices.map(cd => cd.deviceId);
  return data?.devices.filter(device => deviceIds.includes(device.id)) ?? [];
}

/**
 * Returns the main selected device in the workflow builder reducer config.
 * In the case of multi-stage workflows, then this device would be the main
 * device for the specifically selected stage.
 *
 * See getSelectedMainDevice() for details on criteria of main device selection.
 */
export function useGetSelectedMainDevice() {
  const workflowConfig = useWorkflowBuilderSelector(state => state.config);
  const selectedStageId = useWorkflowBuilderSelector(state => state.selectedStageId);
  const stages = useWorkflowBuilderSelector(state => state.stages);

  const configuredDevices = useMemo<ConfiguredDevice[]>(() => {
    const configuredDevicesForStage: ConfiguredDevice[] = [];
    const selectedStage = stages.find(stage => stage.id === selectedStageId);
    if (selectedStage) {
      workflowConfig.configuredDevices?.forEach(device => {
        if (selectedStage?.configuredDevices.includes(device.id)) {
          configuredDevicesForStage.push(device);
        }
      });
    }
    return configuredDevicesForStage;
  }, [selectedStageId, stages, workflowConfig.configuredDevices]);

  const devices = useGetDeviceCommonForWorkflow(configuredDevices);
  return getSelectedMainDevice(configuredDevices, devices);
}

/**
 * Calls Appserver which calls Antha Core Service to get the proper deck layout
 * based on the run config specified.
 *
 * If run config id is not passed in, we will return the default run config
 * even if there are multiple configs available for this particular device. Generally, we will make
 * users pick the run config before parsing the device config.
 *
 * In the case of Gilson, there is no config id, but there is still a deck layout available.
 */
export function useParseDeviceRunConfig(deviceId: string, runConfigId?: string) {
  const { data, loading, error } = useQuery(QUERY_DEVICE_CONFIG_RESPONSE, {
    variables: { id: deviceId, runConfigId },
    skip: deviceId === undefined || deviceId === '',
  });
  return { data, loading, error };
}

export type LayoutOptions = Record<SimpleLabwareType, string[]>;

/**
 * Returns the valid positions present in validAddresses for the given labwareType.
 * For namedPlates, we create a union of the inputPlates and outputPlates as valid
 * positions.
 */
export function getValidPositionsForLabwareType(
  labwareType: LabwareType,
  validAddresses: LayoutOptions,
): string[] {
  let validPositions: string[];
  if (isNamedPlate(labwareType)) {
    validPositions = [
      ...new Set([...validAddresses['inputPlates'], ...validAddresses['outputPlates']]),
    ];
  } else {
    validPositions = validAddresses[labwareType] ?? [];
  }
  return validPositions;
}

/**
 * Some plates are too small to show deck position name or any other extra information.
 * For this there is a tooltip to contain that information for smaller plates.
 * This function returns styles for this tooltip.
 */
export function getSmallDeckPositionTooltipStyles(
  isDeckPositionSelectable: boolean = false,
) {
  return {
    backgroundColor: Colors.GREY_0,
    border: `1px solid ${isDeckPositionSelectable ? Colors.BLUE_50 : Colors.GREY_30}`,
    padding: 4,

    [`& .${tooltipClasses.arrow}`]: {
      color: isDeckPositionSelectable ? Colors.BLUE_50 : Colors.GREY_0,
    },
  };
}

export function useDeckPositionTooltip() {
  const { isPopoverOpen, onShowPopover, onHidePopover } = usePopover();
  const [lock, setLock] = useState(false);

  return {
    open: !lock && isPopoverOpen,
    lock: () => setLock(true),
    unlock: () => setLock(false),
    show: (event: React.MouseEvent<HTMLElement>) => !lock && onShowPopover(event),
    hide: () => onHidePopover(),
    hideThrottled: useThrottle(onHidePopover, 100),
  };
}
