import React, { useMemo } from 'react';

import Stack from '@mui/material/Stack';
import { styled, SxProps, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import DeckPositionWithPreferences from 'client/app/apps/workflow-builder/panels/workflow-settings/deck-options/DeckPositionWithPreferences';
import {
  DeckValidationError,
  DeckValidationPreview,
} from 'client/app/components/DeviceLibrary/DeviceConfigurationValidation/types';
import { getLabwareTypesByPosition } from 'client/app/state/LabwarePreference';
import { ValidAddresses } from 'common/types/device';
import { DeckState } from 'common/types/mix';
import { MessagePreview } from 'common/ui/components/MessagePreview';
import Carrier, { isCarrier } from 'common/ui/components/simulation-details/mix/Carrier';
import DeckLayout from 'common/ui/components/simulation-details/mix/DeckLayout';
import MixGrid from 'common/ui/components/simulation-details/mix/MixGrid';
import Workspace from 'common/ui/components/Workspace/Workspace';

type Props = {
  deck: DeckValidationPreview | null;
  error: DeckValidationError | null;
};

export default function Preview({ deck, error }: Props) {
  if (deck) {
    return <DeckPreview layout={deck.layout} validAddresses={deck.validAddresses} />;
  }
  if (error) {
    return <ErrorPreview error={error} />;
  }
  throw new Error('[DeckLayoutPreview]: no deck and no error found');
}

type DeckPreviewProps = {
  layout: DeckState;
  validAddresses: ValidAddresses;
};

const DeckPreview = React.memo(function DeckPreview({
  layout,
  validAddresses,
}: DeckPreviewProps) {
  const { deckLayout, allDeckPositions, validLabwareTypesByPosition } = useMemo(() => {
    const deckLayout = new DeckLayout({
      before: layout,
      after: layout,
      version: '',
    });
    const validLabwareTypesByPosition = getLabwareTypesByPosition(validAddresses);
    return {
      deckLayout,
      allDeckPositions: deckLayout.getAllDeckPositions(),
      validLabwareTypesByPosition,
    };
  }, [layout, validAddresses]);

  return (
    <Workspace
      isShowHelpButtonVisible={false}
      logCategory="device-configuration-validation"
      canvasControlVariant="light_float"
      isShowAllButtonVisible
      initialShowAll
      gridVisible
    >
      <div style={deckLayout.deckBounds}>
        <MixGrid deckLayout={deckLayout} gridVisible />
        {allDeckPositions.map(deckPosition => {
          const positionName = deckPosition.deckPositionName;
          if (isCarrier(deckPosition)) {
            return <Carrier key={positionName} deckPosition={deckPosition} />;
          }
          return (
            <DeckPositionWithPreferences
              key={positionName}
              deckPosition={deckPosition}
              validLabwareTypes={validLabwareTypesByPosition[positionName]}
              isDisabled
            />
          );
        })}
      </div>
    </Workspace>
  );
});

function ErrorPreview({ error }: { error: DeckValidationError }) {
  const openIntercom = () => {
    const element = document.getElementById('intercomCustomLauncher');
    if (element) {
      element.click();
    }
  };

  const renderMessage = (message: string) => (
    <MessagePreview
      message={message}
      messageType={error.messageType}
      sx={sxErrorMessage}
    />
  );

  return (
    <Container>
      <Typography variant="subtitle2">Error with selected deck layout:</Typography>
      <Stack gap={2} marginTop={3}>
        {error.details ? (
          <>
            <Typography variant="subtitle2" className="errorMessageTitle">
              Summary
            </Typography>
            {renderMessage(error.message)}
            <Stack gap={2} marginTop={3}>
              <Typography variant="subtitle2" className="errorMessageTitle">
                Details
              </Typography>
              {renderMessage(error.details)}
            </Stack>
          </>
        ) : (
          renderMessage(error.message)
        )}
      </Stack>
      <ReUpload variant="body2">
        Please re-generate your configuration or{' '}
        <IntercomLink onClick={openIntercom}>ask for support</IntercomLink>
      </ReUpload>
    </Container>
  );
}

const sxErrorMessage: SxProps<Theme> = {
  padding: (theme: Theme) => theme.spacing(5),
  color: (theme: Theme) => theme.palette.text.primary,
  fontWeight: 400,
  '&:only-child': {
    color: (theme: Theme) => theme.palette.error.main,
    fontWeight: 500,
  },
};

const Container = styled('div')(({ theme }) => ({
  padding: theme.spacing(5),
  '& .errorMessageTitle': {
    color: theme.palette.error.main,
    fontWeight: 700,
  },
}));

const ReUpload = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(6),
}));

const IntercomLink = styled('a')({
  cursor: 'pointer',
  textDecoration: 'underline',
});
